import { defineMessages } from 'react-intl';
import { ContractAnnexType } from '@cooltra/station-based-api';

export default defineMessages({
  annex: {
    defaultMessage: 'Annex',
  },
});

export const cancelledAnnexTypesMessages = defineMessages<ContractAnnexType>({
  EXTENSION: {
    defaultMessage: 'Cancelled extension of {days} days',
  },
  CHANGE_VEHICLE: {
    defaultMessage: 'Cancelled extension of change vehicle',
  },
});
