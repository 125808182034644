import { defineMessages } from 'react-intl';
import { ContractAnnexType } from '@cooltra/station-based-api';

export default defineMessages({
  annex: {
    defaultMessage: 'Annex',
  },
  pendingToSign: {
    defaultMessage: 'Pending to sign',
  },
  cancelAnnex: {
    defaultMessage: 'Cancel annex',
  },
  annexCanceledSuccessfully: {
    defaultMessage: 'Annex canceled successfully',
  },
});

export const pendingSignAnnexTypesMessages = defineMessages<ContractAnnexType>({
  EXTENSION: {
    defaultMessage: 'Extension of {days} days',
  },
  CHANGE_VEHICLE: {
    defaultMessage: 'Vehicle change',
  },
});
