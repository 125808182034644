import { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { System } from '@cooltra/auth-api';

import { countries } from './messages';

export type Country = keyof typeof countries;

const systemCountries: { [key in System]: Country } = {
  Barcelona: 'spain',
  Berlin: 'germany',
  Munich: 'germany',
  Lisbon: 'portugal',
  Madrid: 'spain',
  Malaga: 'spain',
  Mallorca: 'spain',
  Seville: 'spain',
  Valencia: 'spain',
  Milan: 'italy',
  Rome: 'italy',
  Turin: 'italy',
  Paris: 'france',
  Zurich: 'switzerland',
  Amsterdam: 'netherlands',
  Breda: 'netherlands',
  Den_bosch: 'netherlands',
  The_hague: 'netherlands',
  Groningen: 'netherlands',
  Haarlem: 'netherlands',
  Nijmegen: 'netherlands',
  Rotterdam: 'netherlands',
  Tilburg: 'netherlands',
  Zwolle: 'netherlands',
  Enschede: 'netherlands',
  Hilversum: 'netherlands',
  Brussels: 'belgium',
  Antwerp: 'belgium',
  Arnhem: 'netherlands',
  Eindhoven: 'netherlands',
  Delft: 'netherlands',
};

export type SystemCountryProps = Omit<
  HTMLAttributes<HTMLSpanElement>,
  'children'
> & {
  system: System;
};

export const SystemCountry = ({ system, ...rest }: SystemCountryProps) => (
  <FormattedMessage {...countries[systemCountries[system]]} {...rest} />
);
