import { Card, Placeholder, CheckboxPlaceholder } from '@cooltra/ui';

import { VehicleRowLayout } from '../VehicleRowLayout/VehicleRowLayout';

export const VehicleCardLoading = () => (
  <Card className="h-24 flex items-center">
    <VehicleRowLayout
      checkbox={<CheckboxPlaceholder />}
      info={
        <div className="flex items-center">
          <Placeholder className="h-2 w-2 rounded-full mr-7 shrink-0" />
          <Placeholder className="h-10 w-10 rounded-full shrink-0" />
          <div className="pl-6">
            <Placeholder className="h-2 w-40 mb-3" />
            <Placeholder className="h-2 w-32" />
          </div>
        </div>
      }
      system={<Placeholder className="h-2 w-20" />}
      tasks={<Placeholder className="h-2 w-20" />}
      location={
        <div>
          <Placeholder className="h-2 w-20 mb-3" />
          <Placeholder className="h-2 w-32" />
        </div>
      }
      services={
        <div>
          <Placeholder className="h-2 w-20 mb-3" />
          <Placeholder className="h-2 w-20" />
        </div>
      }
    />
  </Card>
);
