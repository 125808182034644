import { FormattedMessage, useIntl } from 'react-intl';
import {
  ContractPayment,
  ContractStatus,
  useInvoicePdfQuery,
} from '@cooltra/station-based-api';
import { capitalize, classNames } from '@cooltra/utils';
import { MdChevronRight } from 'react-icons/md';
import { useToggle } from '@cooltra/hooks';
import { Button, Link } from '@cooltra/ui';
import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';

import { Amount, DateTime } from '~/common';
import { useNotification } from '~/hooks';
import { getEnvVariable } from '~/utils/environment';

import {
  contractPaymentMessages,
  contractPaymentTitleMessages,
  default as messages,
} from '../../ContractPaymentsItems/messages';
import * as ContractPaymentsItems from '../../ContractPaymentsItems';

import { RefundButton } from './RefundButton';

type PaidTicketSummaryProps = {
  contractId: string;
  contractStatus: ContractStatus;
  payment: ContractPayment;
};

const getStripeHref = (stripeId: string) => {
  const accountPath = '/acct_1ArSXbLD1f1asXZR';

  const testPath =
    getEnvVariable('ENVIRONMENT') === 'production' ? '' : '/test';

  return `https://dashboard.stripe.com${accountPath}${testPath}/customers/${stripeId}`;
};

export const PaidTicketSummary = ({
  contractId,
  contractStatus,
  payment,
}: PaidTicketSummaryProps) => {
  const {
    paidAt,
    paymentMethod,
    status,
    totalPrice: { currency, value },
    invoiceId,
    creditNoteId,
  } = payment;

  const [isExpanded, { toggle }] = useToggle();
  const { addErrorNotification } = useNotification();
  const { formatMessage } = useIntl();
  const {
    fetchStatus: invoiceFetchStatus,
    refetch: invoiceRefetch,
    error: invoiceError,
  } = useInvoicePdfQuery(invoiceId || '', {
    enabled: false,
  });
  const {
    fetchStatus: creditNoteFetchStatus,
    refetch: creditNoteRefetch,
    error: creditNoteError,
  } = useInvoicePdfQuery(creditNoteId || '', {
    enabled: false,
  });

  const checkError = useCallback(
    (error: AxiosError) => {
      switch (error.response?.status) {
        case 404:
          addErrorNotification(formatMessage(messages.pdfDoesNotExist));
          break;
        default:
          addErrorNotification(
            formatMessage(messages.somethingWentWrongOnDownloadingPdf)
          );
          break;
      }
    },
    [addErrorNotification, formatMessage]
  );

  useEffect(() => {
    if (invoiceError) {
      checkError(invoiceError);
    }
  }, [addErrorNotification, invoiceError, formatMessage, checkError]);

  useEffect(() => {
    if (creditNoteError) {
      checkError(creditNoteError);
    }
  }, [addErrorNotification, checkError, creditNoteError, formatMessage]);

  return (
    <>
      <div
        className={classNames(
          'flex group items-center justify-between mb-8',
          isExpanded && 'is-expanded'
        )}
      >
        <div>
          <h2 className="text-lg text-neutral-800 mb-2">
            <FormattedMessage
              {...contractPaymentTitleMessages[paymentMethod!]}
            />
          </h2>
          <div className="flex items-center gap-3 text-neutral-500">
            <span>
              <DateTime
                date={paidAt}
                dateClassName="text-neutral-500 text-base"
                timeClassName="text-neutral-500 text-base"
              />
            </span>
            <span className="text-neutral-100"> | </span>
            {paymentMethod === 'CARD' &&
            payment.paymentMethodDetails !== null ? (
              <Link
                href={getStripeHref(payment.paymentMethodDetails.customerId)}
                target="_blank"
              >
                <span>
                  {capitalize(payment.paymentMethodDetails.brand).concat(
                    ' ···· ',
                    payment.paymentMethodDetails?.lastFourDigits
                  )}
                </span>
              </Link>
            ) : (
              <span>
                <FormattedMessage
                  {...contractPaymentMessages[paymentMethod!]}
                />
              </span>
            )}
            {invoiceId && (
              <Button
                size="sm"
                loading={invoiceFetchStatus === 'fetching'}
                onClick={() => invoiceRefetch()}
                emphasis="medium"
              >
                <FormattedMessage {...messages.invoicePdf} />
              </Button>
            )}
            {creditNoteId && (
              <Button
                size="sm"
                loading={creditNoteFetchStatus === 'fetching'}
                onClick={() => creditNoteRefetch()}
                emphasis="medium"
              >
                <FormattedMessage {...messages.creditNotePdf} />
              </Button>
            )}
          </div>
        </div>
        <div className="flex items-center gap-5">
          <div className="flex items-center gap-5">
            <RefundButton
              contractId={contractId}
              contractStatus={contractStatus}
              payment={payment}
            />
            <span
              className={classNames(
                'text-xl font-semibold text-neutral-800',
                status === 'REFUNDED' ? 'line-through' : ''
              )}
            >
              <Amount value={value} currency={currency} />
            </span>
          </div>
          <button
            aria-label="Expand summary"
            className="rotate-90 transition duration-150 group-[.is-expanded]:rotate-270"
            onClick={toggle}
          >
            <MdChevronRight className="text-3xl text-neutral-800" />
          </button>
        </div>
      </div>
      {isExpanded && (
        <>
          <ContractPaymentsItems.Vehicle className="mb-2" payment={payment} />
          <ContractPaymentsItems.Coverage
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentsItems.Accessories
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentsItems.Penalties
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentsItems.ValueAdjustment
            contractId={contractId}
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentsItems.Deposit
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
          <ContractPaymentsItems.Total
            className="mb-10 mt-6 pt-6 border-t border-neutral-100"
            payment={payment}
          />
        </>
      )}
    </>
  );
};
