import { useState, useEffect, Dispatch } from 'react';
import { useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import {
  useActivateMutation,
  useRejectMutation,
  useRequestChangesMutation,
  User,
  useCheckUserQuery,
  RejectReason,
  RequestChangesReason,
} from '@cooltra/api';
import { getErrorStatus, ZeusErrorData } from '@cooltra/axios';
import { useFormContext } from '@cooltra/form';
import { NotificationVariant } from '@cooltra/ui';
import { useNotificationContext } from '@cooltra/notifications';

import { UserPhoto } from '../../../UserPhoto';
import { ActivateButton } from '../ActivateButton/ActivateButton';
import { RejectAlert } from '../RejectAlert/RejectAlert';
import { ReviewFormType, ReviewFormValues } from '../../review-form-types';
import { ReviewHeaderLayout } from '../ReviewHeaderLayout';
import { ReviewHeaderInfo } from '../ReviewHeaderInfo/ReviewHeaderInfo';
import { RequestChangesAlert } from '../RequestChangesAlert/RequestChangesAlert';
import {
  getActivatePayload,
  getRequestChangesPayload,
  getRejectPayload,
} from '../../payload';

import { getRequestChangesReasons } from './request-changes';
import { getRejectReasons } from './reject';
import messages, { reviewOutcomeTexts } from './messages';
import { ReviewOutcome } from './types';

const reviewOutcomeVariants: {
  [key in ReviewOutcome]: NotificationVariant;
} = {
  rejected: 'danger',
  requestedChanges: 'warning',
  activated: 'success',
};

export type ReviewHeaderProps = {
  user: User;
  formType: ReviewFormType;
  setFormType: Dispatch<React.SetStateAction<ReviewFormType>>;
  onFinish: () => void;
};

export const ReviewHeader = ({
  user,
  formType,
  setFormType,
  onFinish,
}: ReviewHeaderProps) => {
  const { id, photos, version } = user;

  const [submissionFailed, submissionError] = useState(false);
  const { values, setSubmitting, validateForm } =
    useFormContext<ReviewFormValues>();

  useEffect(() => {
    if (submissionError) {
      submissionError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const { addNotification } = useNotificationContext();
  const { formatMessage } = useIntl();

  const onSuccess = (outcome: ReviewOutcome) => () => {
    addNotification({
      variant: reviewOutcomeVariants[outcome],
      text: formatMessage(reviewOutcomeTexts[outcome]),
    });
    onFinish();
  };

  const onMutate = () => setSubmitting(true);

  const onSettled = () => setSubmitting(false);

  const { data: idCardNumberCheckData } = useCheckUserQuery(
    id,
    { idCardNumber: values.idCardNumber },
    { enabled: false }
  );

  const { data: driverLicenseNumberCheckData } = useCheckUserQuery(
    id,
    { driverLicenseNumber: values.driverLicenseNumber },
    { enabled: false }
  );

  const onError = (error: AxiosError<ZeusErrorData>) => {
    const status = getErrorStatus(error);
    if (status === 422 && getSubmissionErrorMessages(error).length) {
      submissionError(true);
    } else if (status === 409) {
      addNotification({
        variant: 'danger',
        text: formatMessage(messages.conflictErrorMessage),
      });
    } else {
      addNotification({
        variant: 'danger',
        text: formatMessage(messages.errorMessage),
      });
    }
  };

  const activateMutation = useActivateMutation(id, {
    onMutate,
    onSuccess: onSuccess('activated'),
    onError,
    onSettled,
  });

  const getSubmissionErrorMessages = (
    error: AxiosError<ZeusErrorData> | null
  ) => error?.response?.data?.errors || [];

  const rejectMutation = useRejectMutation(id, {
    onMutate,
    onSuccess: onSuccess('rejected'),
    onError,
    onSettled,
  });

  const requestChangesMutation = useRequestChangesMutation(id, {
    onMutate,
    onSuccess: onSuccess('requestedChanges'),
    onError,
    onSettled,
  });

  const submissionErrorMessages = getSubmissionErrorMessages(
    activateMutation.error ||
      rejectMutation.error ||
      requestChangesMutation.error
  );

  const isErrorForcingReject =
    submissionFailed &&
    submissionErrorMessages &&
    !submissionErrorMessages.includes('Credit card expired');

  const isErrorForcingChanges =
    submissionFailed &&
    submissionErrorMessages &&
    submissionErrorMessages.includes('Credit card expired');

  const rejectReasons = isErrorForcingReject
    ? (submissionErrorMessages as RejectReason[])
    : getRejectReasons({
        ...values,
        isIdCardDuplicated: idCardNumberCheckData?.idCardNumber === false,
        isDriverLicenseDuplicated:
          driverLicenseNumberCheckData?.driverLicenseNumber === false,
      });

  const requestChangesReasons = isErrorForcingChanges
    ? (submissionErrorMessages as RequestChangesReason[])
    : getRequestChangesReasons(values);

  useEffect(() => {
    if (rejectReasons.length) {
      setFormType('reject');
    } else if (requestChangesReasons.length) {
      setFormType('request-changes');
    } else {
      setFormType('activate');
    }
  }, [
    isErrorForcingChanges,
    isErrorForcingReject,
    rejectReasons.length,
    requestChangesReasons.length,
    setFormType,
  ]);

  useEffect(() => {
    validateForm();
  }, [formType, validateForm]);

  return (
    <ReviewHeaderLayout
      renderImage={<UserPhoto initialSize="sm" imageKey={photos.selfie} />}
      renderContent={
        <div className="flex gap-12">
          <ReviewHeaderInfo user={user} />
          <div className="grow">
            <div className="h-full flex justify-end items-end">
              {formType === 'reject' ? (
                <RejectAlert
                  onReject={() => {
                    rejectMutation.mutate(
                      getRejectPayload(values, rejectReasons, version)
                    );
                  }}
                  reasons={rejectReasons}
                  userEmailWithSameField={
                    idCardNumberCheckData?.userEmailWithSameField ||
                    driverLicenseNumberCheckData?.userEmailWithSameField
                  }
                  isLoading={rejectMutation.isPending}
                />
              ) : formType === 'request-changes' ? (
                <RequestChangesAlert
                  reasons={requestChangesReasons}
                  onRequestChanges={() => {
                    requestChangesMutation.mutate(
                      getRequestChangesPayload(
                        values,
                        requestChangesReasons,
                        version
                      )
                    );
                  }}
                  isLoading={requestChangesMutation.isPending}
                />
              ) : (
                <ActivateButton
                  onActivate={() =>
                    activateMutation.mutate(getActivatePayload(values, version))
                  }
                  loading={activateMutation.isPending}
                />
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};
