import { MdErrorOutline } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message, Spinner } from '@cooltra/ui';
import { getVehicleName, vehicleImages } from '@cooltra/utils';
import {
  useContractAvailableVehicleTypesQuery,
  Contract,
} from '@cooltra/station-based-api';

import {
  ContractEditionAlert,
  ContractPendingSignAlert,
  ErrorPage,
} from '~/common';
import { useContractFlags } from '~/hooks';

import { VehicleTypeCard } from '../VehicleTypeCard';

import messages from './messages';

export type ContractVehicleTypesProps = {
  contract: Contract;
};

export const ContractVehicleTypes = ({
  contract: { contractId, status, vehicle },
}: ContractVehicleTypesProps) => {
  const { formatMessage } = useIntl();
  const { isSignedOrPendingSignature } = useContractFlags();
  const {
    data: vehicleTypes,
    isLoading,
    isError,
  } = useContractAvailableVehicleTypesQuery(contractId, {
    enabled: Boolean(contractId),
  });

  if (isLoading) {
    return (
      <div className="w-full text-center mt-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!vehicleTypes || vehicleTypes?.length === 0) {
    return (
      <div className="container flex justify-center py-16 tc max-w-5xl">
        <Message
          icon={<MdErrorOutline />}
          title={formatMessage(messages.vehicleNotFoundTitle)}
          text={formatMessage(messages.vehicleNotFoundContent)}
        />
      </div>
    );
  }

  const getVehicles = () => {
    if (!vehicle) {
      return (
        <div className="grid grid-cols-2 gap-4">
          {vehicleTypes.map((vehicleType) => (
            <VehicleTypeCard
              key={vehicleType.id}
              id={vehicleType.id}
              name={vehicleType.name}
              vehicleTypeName={getVehicleName(vehicleType.name)}
              vehicleTypeImage={vehicleImages[vehicleType.name]}
              price={vehicleType.price}
              contractId={contractId}
            />
          ))}
        </div>
      );
    }

    const { assignedVehicle, name, price, vehicleTypeId } = vehicle;

    return (
      <div className="grid grid-cols-1">
        <VehicleTypeCard
          id={vehicleTypeId}
          name={name}
          vehicleTypeName={
            assignedVehicle ? assignedVehicle.model : getVehicleName(name)
          }
          vehicleTypeImage={vehicleImages[name]}
          vehicleLicensePlate={assignedVehicle?.licensePlate}
          price={price}
          contractId={contractId}
          vehicleTypeIsAdded
        />
      </div>
    );
  };

  return (
    <div data-testid="CONTRACT_VEHICLE_TYPES">
      <div className="mx-auto max-w-4xl pt-4 pb-10">
        {!isSignedOrPendingSignature && (
          <ContractEditionAlert contractStatus={status} />
        )}
        <ContractPendingSignAlert />
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl">
            <FormattedMessage {...messages.vehicle} />
          </h2>
        </div>
        {getVehicles()}
      </div>
    </div>
  );
};
