import { defineMessages } from 'react-intl';

export default defineMessages({
  annex: {
    defaultMessage: 'Annex',
  },
  pendingToPay: {
    defaultMessage: 'Pending delivery checks',
  },
  typeName: {
    defaultMessage: 'Vehicle change',
  },
});
