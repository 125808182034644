import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    defaultMessage: 'ID {id}',
  },
  age: {
    defaultMessage: 'Age {age}',
  },
  overview: {
    defaultMessage: 'Overview',
  },
  profile: {
    defaultMessage: 'Profile',
  },
  review: {
    defaultMessage: 'Activation',
  },
  billing: {
    defaultMessage: 'Billing & Subscription',
  },
  rentals: {
    defaultMessage: 'Rentals',
  },
  reservations: {
    defaultMessage: 'Reservations',
  },
  reports: {
    defaultMessage: 'Reports',
  },
  incidents: {
    defaultMessage: 'Incidents',
  },
  suspensions: {
    defaultMessage: 'Suspensions',
  },
  userSessions: {
    defaultMessage: 'User sessions',
  },
  billingAndSubscriptions: {
    defaultMessage: 'Billing & Subscriptions',
  },
  reviewUser: {
    defaultMessage: 'Review user',
  },
});
