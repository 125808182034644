import { IntlShape } from 'react-intl';
import { stdnum } from 'stdnum';
import { nif } from 'pt-id';
import { validateSpanishId } from 'spain-id';
import { differenceInYears, parse } from 'date-fns';

import { dateFormat, isPast } from '~/utils/date';
import { isInvalidEmailDomain } from '~/utils/emails';

import {
  dateRegex,
  emailRegex,
  noNumbers,
  phoneRegex,
  amountRegex,
  timeRegex,
  noSpecialCharacters,
  amountWithoutDecimalsRegex,
} from './regex';
import messages from './messages';

export const validateName = (intl: IntlShape, value: string) =>
  noNumbers.test(value) ? undefined : intl.formatMessage(messages.invalidName);

export const validateAmount = (intl: IntlShape, value: string) =>
  amountRegex.test(value)
    ? undefined
    : intl.formatMessage(messages.invalidAmount);

export const validateAmountWithoutDecimals = (
  intl: IntlShape,
  value: string
) =>
  amountWithoutDecimalsRegex.test(value)
    ? undefined
    : intl.formatMessage(messages.invalidAmount);

export const validateCity = (intl: IntlShape, value: string) =>
  noNumbers.test(value) ? undefined : intl.formatMessage(messages.invalidCity);

export const validateRequired = (intl: IntlShape, value: any) =>
  value === null || value === undefined || value === ''
    ? intl.formatMessage(messages.required)
    : undefined;

export const validateDateRequired = (intl: IntlShape, value: Date) =>
  value === undefined ? intl.formatMessage(messages.required) : undefined;

export const validateMaxLength = (
  intl: IntlShape,
  value: string,
  length: number
) =>
  value.length <= length
    ? undefined
    : intl.formatMessage(messages.invalidMaxLength, { length });

export const validateExactLength = (
  intl: IntlShape,
  value: string,
  length: number
) =>
  value.length === length
    ? undefined
    : intl.formatMessage(messages.invalidExactLength, { length });

export const validateRequiredCheckboxGroup = (
  intl: IntlShape,
  value: Record<string, boolean>
) =>
  Object.values(value).find(Boolean)
    ? undefined
    : intl.formatMessage(messages.required);

export const isValidDate = (value: string) => !!value.match(dateRegex);

export const validateDate = (intl: IntlShape, value: string) =>
  isValidDate(value) ? undefined : intl.formatMessage(messages.invalidDate);

export const validateAdult = (intl: IntlShape, value: string) => {
  const today = new Date();
  return differenceInYears(today, parse(value, dateFormat, today)) >= 18
    ? undefined
    : intl.formatMessage(messages.notAdult);
};

export const validateTime = (intl: IntlShape, value: string) =>
  value.match(timeRegex) ? undefined : intl.formatMessage(messages.invalidTime);

export const validateEmail = (intl: IntlShape, value: string) => {
  if (isInvalidEmailDomain(value)) {
    return intl.formatMessage(messages.invalidEmail);
  }
  return emailRegex.test(value)
    ? undefined
    : intl.formatMessage(messages.invalidEmail);
};

const countryTaxIdValidators = {
  ES: (value: string) => validateSpanishId(value),
  PT: (value: string) => nif.validate(value),
  IT: (value: string) => stdnum.IT.codicefiscale.validate(value).isValid,
};

const isValidatedTaxCountry = (
  country: string
): country is keyof typeof countryTaxIdValidators =>
  Object.keys(countryTaxIdValidators).includes(country);

export const validateId = (intl: IntlShape, value: string, country: string) => {
  if (isValidatedTaxCountry(country)) {
    return countryTaxIdValidators[country](value)
      ? undefined
      : intl.formatMessage(messages.invalidId);
  }
  return undefined;
};

export const validateTaxId = (
  intl: IntlShape,
  value: string,
  country: string
) => {
  if (isValidatedTaxCountry(country)) {
    return countryTaxIdValidators[country](value)
      ? undefined
      : intl.formatMessage(messages.invalidTaxId);
  }
  return undefined;
};

export const validateStationBasedId = (
  intl: IntlShape,
  value: string,
  userCountry: string
) => {
  if (userCountry === 'ES') {
    return countryTaxIdValidators.ES(value)
      ? undefined
      : intl.formatMessage(messages.invalidId);
  }
  return undefined;
};

export const validateStationBasedTaxId = (
  intl: IntlShape,
  value: string,
  userCountry: string,
  servicePointCountry?: string
) => {
  if (userCountry === 'ES') {
    return countryTaxIdValidators.ES(value)
      ? undefined
      : intl.formatMessage(messages.invalidTaxId);
  }
  if (
    (userCountry === 'PT' && servicePointCountry === 'PT') ||
    (userCountry === 'IT' && servicePointCountry === 'IT')
  ) {
    return countryTaxIdValidators[userCountry](value)
      ? undefined
      : intl.formatMessage(messages.invalidTaxId);
  }
  return undefined;
};

export const validatePhone = (intl: IntlShape, value: string) =>
  phoneRegex.test(value)
    ? undefined
    : intl.formatMessage(messages.invalidPhone);

export const validateExpirationDate = (intl: IntlShape, value: string) =>
  isPast(value)
    ? intl.formatMessage(messages.invalidExpirationDate)
    : undefined;

export const validateNoSpecialCharacters = (intl: IntlShape, value: string) =>
  noSpecialCharacters.test(value)
    ? undefined
    : intl.formatMessage(messages.invalidCharacters);

export const createSetError =
  <ErrorKey extends string>(errors: Partial<Record<ErrorKey, string>>) =>
  (errorKey: ErrorKey, message: string | undefined) => {
    if (message) {
      errors[errorKey] = message;
    }
  };

export const errorMessages = messages;
