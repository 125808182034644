import { defineMessages } from 'react-intl';

export default defineMessages({
  oldestFirst: {
    defaultMessage: 'Oldest first',
  },
  newestFirst: {
    defaultMessage: 'Newest first',
  },
  newestCommentFirst: {
    defaultMessage: 'Newest comment first',
  },
});
