import { User } from '@cooltra/api';
import { CopyButton } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import {
  MdLocationOn,
  MdCall,
  MdPerson,
  MdMail,
  MdSms,
  MdCake,
} from 'react-icons/md';

import { calculateAge } from '~/utils/date';
import messages from '~/components/User/Account/Sidebar/messages';

import { UserLanguage } from '../../../UserLanguage/UserLanguage';
import { SystemCity } from '../../../../System';

import { UserDataItem } from './UserDataItem';

export type ReviewHeaderInfoProps = { user: User };

export const ReviewHeaderInfo = ({
  user: { name, dateOfBirth, email, phone, language, id, system },
}: ReviewHeaderInfoProps) => (
  <div>
    <h2 className="text-neutral-700 text-2xl mb-3 leading-none">
      <span className="pr-1">{name}</span>
    </h2>
    <div className="flex gap-x-10">
      <ul className="flex flex-col gap-y-1">
        <li className="flex w-full justify-between text-sm">
          <UserDataItem icon={<MdMail />} label={email} />
          <CopyButton className="text-base pl-2 mb-0.5" value={email} />
        </li>
        <li className="text-sm">
          <UserDataItem icon={<MdCall />} label={phone} />
        </li>
        <li className="text-sm">
          <UserDataItem
            icon={<MdSms className="mt-0.5" />}
            label={<UserLanguage language={language} />}
          />
        </li>
      </ul>
      <ul className="flex flex-col gap-y-1">
        <li className="text-sm">
          <UserDataItem
            icon={<MdPerson />}
            label={
              <span className="inline-flex">
                <span className="inline-block align-middle truncate max-w-full w-40 mr-1">
                  {id}
                </span>
                <CopyButton className="text-base mb-0.5" value={id} />
              </span>
            }
          />
        </li>
        <li className="text-sm">
          <UserDataItem
            icon={<MdLocationOn />}
            label={<SystemCity system={system} />}
          />
        </li>
        <li className="text-sm">
          <UserDataItem
            icon={<MdCake className="mt-0.5" />}
            label={
              <FormattedMessage
                {...messages.age}
                values={{ age: calculateAge(dateOfBirth) }}
              />
            }
          />
        </li>
      </ul>
    </div>
  </div>
);
