import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { classNames } from '@cooltra/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type VehicleRowLayoutProps = HTMLAttributes<HTMLDivElement> & {
  checkbox: ReactNode;
  info: ReactNode;
  tasks: ReactNode;
  location: ReactNode;
  services: ReactNode;
  system: ReactNode;
};

export const VehicleRowLayout = ({
  checkbox,
  info,
  tasks,
  location,
  services,
  className,
  system,
  ...rest
}: VehicleRowLayoutProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  const canSelectVehicles =
    hasPermission('bulk:actions') &&
    (hasPermission('transfer:vehicles') || hasPermission('bulk:tasks'));

  return (
    <div
      className={classNames('flex items-center w-full h-full pl-8', className)}
      {...rest}
    >
      {canSelectVehicles && (
        <div className="flex justify-center">{checkbox}</div>
      )}
      <div
        className={classNames(
          'grid grid-cols-8 w-full items-center',
          canSelectVehicles && 'pl-8'
        )}
      >
        <div className="col-span-2">{info}</div>
        <div className="col-span-1">{system}</div>
        <div className="col-span-1">{tasks}</div>
        <div className="col-span-2">{location}</div>
        <div className="col-span-2">{services}</div>
      </div>
    </div>
  );
};
