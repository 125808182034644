import { RejectReason } from '@cooltra/api';

import { isAdult } from '~/utils/date';
import { isValidDate } from '~/validation';

import { ReviewFormValues } from '../../review-form-types';

export const getRejectReasons = ({
  arePhotosValid,
  isValidId,
  isFraud,
  dateOfBirth,
  isIdCardDuplicated,
  isDriverLicenseDuplicated,
}: ReviewFormValues & {
  isIdCardDuplicated: boolean;
  isDriverLicenseDuplicated: boolean;
}): RejectReason[] => {
  const reasons: RejectReason[] = [];

  if (isDriverLicenseDuplicated) {
    reasons.push('DUPLICATED_DRIVER_LICENSE');
  }

  if (isIdCardDuplicated) {
    reasons.push('DUPLICATED_ID_NUMBER');
  }

  if (!arePhotosValid && isFraud) {
    reasons.push('fraud');
  }

  if (isValidId && isValidDate(dateOfBirth) && !isAdult(dateOfBirth)) {
    reasons.push('minor');
  }

  return reasons;
};
