import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { RejectReason } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';

import { ReviewOutcomeAlert } from '../ReviewOutcomeAlert';
import { useHandleSubmit } from '../useHandleSubmit';

import messages, { rejectReasonMessages } from './messages';
import { TranslatedRejectReason } from './types';

const isTranslatedRejectReason = (
  reason: TranslatedRejectReason | RejectReason
): reason is TranslatedRejectReason => reason !== 'other';

export type RejectAlertProps = {
  onReject: () => void;
  isLoading: boolean;
  reasons: RejectReason[];
  userEmailWithSameField?: string;
};

export const RejectAlert = ({
  onReject,
  isLoading,
  reasons,
  userEmailWithSameField,
}: RejectAlertProps) => {
  const handleClick = useHandleSubmit(onReject);

  const getReason = (reason: TranslatedRejectReason) => {
    if (rejectReasonMessages[reason]) {
      switch (reason) {
        case 'fraud':
        case 'minor':
          return <FormattedMessage {...rejectReasonMessages[reason]} />;
        case 'DUPLICATED_ID_NUMBER':
        case 'DUPLICATED_DRIVER_LICENSE':
          return (
            <FormattedMessage
              {...rejectReasonMessages[reason]}
              values={{
                userEmailWithSameField: (
                  <RouterLink
                    to={`/users/search?keyword=${userEmailWithSameField}`}
                    target="_blank"
                  >
                    {userEmailWithSameField}
                  </RouterLink>
                ),
              }}
            />
          );
      }
    }

    return reason;
  };

  return (
    <ReviewOutcomeAlert
      variant="danger"
      renderHeader={<FormattedMessage {...messages.rejectHeading} />}
      renderList={reasons.filter(isTranslatedRejectReason).map((reason) => (
        <li key={reason}>{getReason(reason)}</li>
      ))}
      renderButton={
        <Button
          variant="danger"
          emphasis="high"
          loading={isLoading}
          onClick={handleClick}
        >
          <FormattedMessage {...messages.rejectButton} />
        </Button>
      }
    />
  );
};
