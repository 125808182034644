import { MdAssignment } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { Card } from '@cooltra/ui';
import { ContractAnnex } from '@cooltra/station-based-api';

import messages from './messages';

type PendingChecksInProps = {
  annex: ContractAnnex;
};

export const PendingChecksIn = ({ annex: { id } }: PendingChecksInProps) => (
  <Card key={id} className="flex items-center p-5 gap-7">
    <div className="flex-1 flex items-center gap-4 pl-2">
      <MdAssignment
        className="text-warning-500 text-xl"
        data-testid="NEW_ANNEX_CHANGE_VEHICLE"
      />
      <h4 className="font-semibold text-neutral-800 text-base">
        <FormattedMessage {...messages.annex} />
        <p className="flex items-center gap-1 font-normal text-warning-700 text-sm">
          <FormattedMessage {...messages.typeName} />
        </p>
      </h4>
    </div>
    <div className="flex items-center gap-4">
      <p className="font-normal text-neutral-300 text-sm">
        <FormattedMessage {...messages.pendingToPay} />
      </p>
    </div>
  </Card>
);
