import { useIntl } from 'react-intl';
import {
  MdAddCircleOutline,
  MdEuroSymbol,
  MdLibraryAddCheck,
  MdListAlt,
  MdOutlineCalendarMonth,
  MdPersonOutline,
} from 'react-icons/md';
import { Contract } from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import { CustomIcon } from '~/common';
import { useContractFlags } from '~/hooks';

import { isLinkEnabled } from '../utils/is-link-enabled';

import { ContractSidebarLink } from './ContractSidebarLink';
import messages from './messages';

export type ContractSidebarProps = {
  contract: Contract;
};

export const ContractSidebar = ({ contract }: ContractSidebarProps) => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useAuthClaimsQuery();

  const {
    hasDepositBeenWithheld,
    requiresDeposit,
    hasDriversDataWarning,
    hasAssignedVehicle,
    pendingPayment,
    shouldReleaseDeposit,
    areChecksInPending,
    areChecksOutPending,
    hasClickAndRide,
  } = useContractFlags();

  const isChecksPending = areChecksOutPending || areChecksInPending;

  return (
    <div className="w-16 h-full flex flex-col gap-3 bg-neutral-0 z-10 shadow-md relative pt-3">
      <ContractSidebarLink
        to="overview"
        aria-label={formatMessage(messages.overview)}
        disabled={!isLinkEnabled('overview', contract)}
      >
        <MdListAlt className="text-2xl" />
      </ContractSidebarLink>
      <ContractSidebarLink
        to="details"
        aria-label={formatMessage(messages.details)}
        disabled={!isLinkEnabled('details', contract)}
      >
        <MdOutlineCalendarMonth className="text-2xl" />
      </ContractSidebarLink>
      <ContractSidebarLink
        to="driver"
        aria-label={formatMessage(messages.driver)}
        warning={hasDriversDataWarning}
        disabled={!isLinkEnabled('driver', contract)}
      >
        <MdPersonOutline className="text-2xl" />
      </ContractSidebarLink>
      <ContractSidebarLink
        to="vehicles"
        aria-label={formatMessage(messages.vehicle)}
        warning={!hasAssignedVehicle}
        disabled={
          !isLinkEnabled(
            'vehicles',
            contract,
            hasPermission('experimental:see_hidden_features')
          )
        }
      >
        <CustomIcon.Motorcycle className="text-2xl" />
      </ContractSidebarLink>
      <ContractSidebarLink
        to="extras"
        aria-label={formatMessage(messages.extras)}
        disabled={!isLinkEnabled('extras', contract)}
      >
        <MdAddCircleOutline className="text-2xl" />
      </ContractSidebarLink>
      <ContractSidebarLink
        to="payments"
        aria-label={formatMessage(messages.payment)}
        warning={
          (requiresDeposit && !hasDepositBeenWithheld) ||
          shouldReleaseDeposit ||
          pendingPayment
        }
        disabled={!isLinkEnabled('payments', contract)}
      >
        <MdEuroSymbol className="text-2xl" />
      </ContractSidebarLink>
      <ContractSidebarLink
        to="checks-damages"
        aria-label={formatMessage(messages.checksDamages)}
        warning={isChecksPending && hasAssignedVehicle}
        disabled={!isLinkEnabled('checks-damages', contract)}
      >
        <MdLibraryAddCheck className="text-2xl" />
      </ContractSidebarLink>
      {hasClickAndRide && (
        <ContractSidebarLink
          to="click-and-ride"
          aria-label="Click & Ride"
          disabled={!isLinkEnabled('click-and-ride', contract)}
        >
          <CustomIcon.ClickAndRide className="text-2xl" />
        </ContractSidebarLink>
      )}
    </div>
  );
};
