import {
  AvailableVehicle,
  useAssignVehicleToContractMutation,
} from '@cooltra/station-based-api';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Button, Divider } from '@cooltra/ui';
import { VehicleTypeName } from '@cooltra/utils';

import { useNotification } from '~/hooks';

import messages, { statusLabels } from './messages';

export type AvailableVehicleCardProps = {
  contractId: string;
  vehicle: AvailableVehicle;
  onClose: () => void;
};

export const AvailableVehicleCard = ({
  contractId,
  vehicle: { vehicleId, modelName, licensePlate, mileage, status },
  onClose,
}: AvailableVehicleCardProps) => {
  const intl = useIntl();
  const { mutateAsync, isPending } =
    useAssignVehicleToContractMutation(contractId);
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const handleOnAssign = () =>
    mutateAsync({
      vehicleId,
      licensePlate,
      model: modelName as VehicleTypeName,
      mileage,
    })
      .then(() => {
        addSuccessNotification(
          intl.formatMessage(messages.theLicensePlateHasBeenAssigned)
        );
        onClose();
      })
      .catch(() => addErrorNotification());

  return (
    <div className="flex items-center py-7">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-1">
          <span className="font-semibold text-lg">{licensePlate}</span>
          <span> - </span>
          <span className="font-normal text-lg">{modelName}</span>
        </div>
        <div className="flex items-center gap-2">
          <span>{intl.formatNumber(mileage)} kms</span>
          <Divider direction="vertical" className="h-6" />
          <Badge variant={status === 'AVAILABLE' ? 'success' : 'neutral'}>
            <FormattedMessage {...statusLabels[status]} />
          </Badge>
        </div>
      </div>
      <Button
        className="ml-auto"
        loading={isPending}
        onClick={handleOnAssign}
        disabled={status !== 'AVAILABLE'}
      >
        <FormattedMessage {...messages.assign} />
      </Button>
    </div>
  );
};
