import { defineMessages } from 'react-intl';

export default defineMessages({
  annex: {
    defaultMessage: 'Annex',
  },
  pendingToPay: {
    defaultMessage: 'Pending to pay',
  },
  extension: {
    defaultMessage: 'Extension of {days} days',
  },
});
