import { CopyButton, Divider, Icon } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { RouterButton, RouterSidebarNavLink } from '@cooltra/navigation';
import {
  MdLocationOn,
  MdSms,
  MdCake,
  MdOutlineChevronRight,
} from 'react-icons/md';
import { User } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import {
  SidebarHeading,
  SidebarIconItem,
  UserLanguage,
  SystemCity,
  SidebarLinkContent,
  SidebarItem,
  UserStateBadge,
} from '~/common';
import { calculateAge } from '~/utils/date';
import { canUserBeReviewed } from '~/utils/users';

import messages from './messages';

export type SidebarProps = {
  user: User;
};

export const Sidebar = ({
  user: {
    state,
    firstName,
    lastName,
    id,
    system,
    language,
    dateOfBirth,
    phone,
    email,
  },
}: SidebarProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  const areRentalsEnabled = hasPermission('read:rentals');

  return (
    <div className="w-72 pt-14 pb-20" data-testid="USER_SIDEBAR">
      <div className="px-8">
        <UserStateBadge state={state} className="mb-4" />
        <SidebarHeading className="mb-4 break-words">
          {firstName} {lastName}
        </SidebarHeading>
        <SidebarItem copyValue={id} value={id} className="mb-9" />
        <ul className="mb-6 flex flex-col gap-2">
          <SidebarIconItem
            icon={<MdLocationOn />}
            label={<SystemCity system={system} />}
          />
          <SidebarIconItem
            icon={<MdSms />}
            label={<UserLanguage language={language} />}
          />
          {dateOfBirth && (
            <SidebarIconItem
              icon={<MdCake />}
              label={
                <FormattedMessage
                  {...messages.age}
                  values={{ age: calculateAge(dateOfBirth) }}
                />
              }
            />
          )}
        </ul>
        <span className="flex text-xs text-neutral-700 mb-1">
          <span className="truncate">{email}</span>
          <CopyButton className="text-sm ml-1" value={email} />
        </span>
        <span className="block text-xs text-neutral-700 mb-7">{phone}</span>
        {hasPermission('write:users') && canUserBeReviewed(state) && (
          <RouterButton
            to={`/users/${id}/review`}
            className="mb-8"
            size="sm"
            trailingIcon={
              <Icon>
                <MdOutlineChevronRight className="text-xl" />
              </Icon>
            }
          >
            <FormattedMessage {...messages.reviewUser} />
          </RouterButton>
        )}
      </div>
      <Divider />
      <ul>
        <li>
          <RouterSidebarNavLink to="overview">
            <SidebarLinkContent>
              <FormattedMessage {...messages.overview} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
        <li>
          <RouterSidebarNavLink to="profile">
            <SidebarLinkContent>
              <FormattedMessage {...messages.profile} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
        <li>
          <RouterSidebarNavLink to="states">
            <SidebarLinkContent>
              <FormattedMessage {...messages.review} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
        <li>
          <RouterSidebarNavLink to="sessions">
            <SidebarLinkContent>
              <FormattedMessage {...messages.userSessions} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
        <li>
          <RouterSidebarNavLink to="billing/subscriptions">
            <SidebarLinkContent>
              <FormattedMessage {...messages.billing} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
        {(state === 'ACTIVATED' ||
          state === 'DELETED' ||
          state === 'SUSPENDED') && (
          <>
            {areRentalsEnabled && (
              <>
                <li>
                  <RouterSidebarNavLink to="rentals">
                    <SidebarLinkContent>
                      <FormattedMessage {...messages.rentals} />
                    </SidebarLinkContent>
                  </RouterSidebarNavLink>
                  <Divider />
                </li>
                <li>
                  <RouterSidebarNavLink to="reservations">
                    <SidebarLinkContent>
                      <FormattedMessage {...messages.reservations} />
                    </SidebarLinkContent>
                  </RouterSidebarNavLink>
                  <Divider />
                </li>
              </>
            )}
            <li>
              {hasPermission('read:problem_reports') && (
                <RouterSidebarNavLink to="problem-reports">
                  <SidebarLinkContent>
                    <FormattedMessage {...messages.reports} />
                  </SidebarLinkContent>
                </RouterSidebarNavLink>
              )}
              <Divider />
            </li>
            <li>
              {hasPermission('read:incidents') && (
                <RouterSidebarNavLink to="incidents">
                  <SidebarLinkContent>
                    <FormattedMessage {...messages.incidents} />
                  </SidebarLinkContent>
                </RouterSidebarNavLink>
              )}
              <Divider />
            </li>
            <li>
              <RouterSidebarNavLink to="suspensions">
                <SidebarLinkContent>
                  <FormattedMessage {...messages.suspensions} />
                </SidebarLinkContent>
              </RouterSidebarNavLink>
              <Divider />
            </li>
          </>
        )}
      </ul>
    </div>
  );
};
