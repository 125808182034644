import { CopyButton, Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { RouterSidebarNavLink } from '@cooltra/navigation';
import { MdLocationOn, MdSms } from 'react-icons/md';
import { MaasUser, UserStateType } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { capitalize } from '@cooltra/utils';

import {
  SidebarHeading,
  SidebarIconItem,
  UserLanguage,
  SystemCity,
  SidebarLinkContent,
  SidebarItem,
  MaasLogo,
  UserStateBadge,
} from '~/common';

import messages from './messages';

export type SidebarProps = {
  maasUser: MaasUser;
};

export const Sidebar = ({
  maasUser: {
    id,
    externalId,
    provider,
    firstName,
    lastName,
    system,
    language,
    phone,
    email,
    deletedAt,
    suspendedAt,
  },
}: SidebarProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  const areRentalsEnabled = hasPermission('read:rentals');

  const isValidMaasUser = provider == 'SIXT' || provider == 'UMOB';
  const canViewInvoices = hasPermission('read:invoices');

  const maasUserState: UserStateType = deletedAt
    ? 'DELETED'
    : suspendedAt
      ? 'SUSPENDED'
      : 'ACTIVATED';

  return (
    <div className="w-72 pt-14 pb-20" data-testid="MAAS_USER_SIDEBAR">
      <div className="px-8">
        <UserStateBadge state={maasUserState} className="mb-4" />
        <SidebarHeading className="mb-4">
          {`${capitalize(firstName?.toLowerCase() || '')} ${capitalize(
            lastName?.toLowerCase() || ''
          )}`}
        </SidebarHeading>
        <SidebarItem copyValue={id} value={id} className="mb-1" />
        <SidebarItem
          value={externalId}
          copyValue={externalId}
          label={<FormattedMessage {...messages.providerId} />}
          className="mb-8"
        />
        <MaasLogo provider={provider} className="mb-6 h-5" />
        <ul className="mb-6 flex flex-col gap-2">
          <SidebarIconItem
            icon={<MdLocationOn />}
            label={<SystemCity system={system} />}
          />
          {language && (
            <SidebarIconItem
              icon={<MdSms />}
              label={<UserLanguage language={language} />}
            />
          )}
        </ul>
        {email && (
          <span className="flex text-xs text-neutral-700 mb-1">
            <span className="truncate">{email}</span>
            <CopyButton className="text-sm ml-1" value={email} />
          </span>
        )}
        <span className="block text-xs text-neutral-700 mb-7">{phone}</span>
      </div>
      <Divider />
      <ul>
        <li>
          <RouterSidebarNavLink to="profile">
            <SidebarLinkContent>
              <FormattedMessage {...messages.profile} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
        {isValidMaasUser && canViewInvoices && (
          <li>
            <RouterSidebarNavLink to="billing/invoices">
              <SidebarLinkContent>
                <FormattedMessage {...messages.billing} />
              </SidebarLinkContent>
            </RouterSidebarNavLink>
            <Divider />
          </li>
        )}
        {areRentalsEnabled && (
          <>
            <li>
              <RouterSidebarNavLink to="reservations">
                <SidebarLinkContent>
                  <FormattedMessage {...messages.reservations} />
                </SidebarLinkContent>
              </RouterSidebarNavLink>
              <Divider />
            </li>
            <li>
              <RouterSidebarNavLink to="rentals">
                <SidebarLinkContent>
                  <FormattedMessage {...messages.rentals} />
                </SidebarLinkContent>
              </RouterSidebarNavLink>
              <Divider />
            </li>
          </>
        )}
        <li>
          <RouterSidebarNavLink to="suspensions">
            <SidebarLinkContent>
              <FormattedMessage {...messages.suspensions} />
            </SidebarLinkContent>
          </RouterSidebarNavLink>
          <Divider />
        </li>
      </ul>
    </div>
  );
};
