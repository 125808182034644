import { Button } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import {
  Contract,
  useUnassignVehicleToContractMutation,
} from '@cooltra/station-based-api';

import { ConfirmModal } from '~/common';
import { useContractFlags, useNotification } from '~/hooks';

import messages from './messages';

export type UnassignLicensePlateButtonProps = {
  contract: Contract;
};

export const UnassignLicensePlateButton = ({
  contract,
}: UnassignLicensePlateButtonProps) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { formatMessage } = useIntl();
  const { addErrorNotification } = useNotification();
  const { areChecksOutPending } = useContractFlags();
  const hasPendingAnnex = contract.annexes?.some(
    (annex) =>
      annex.status === 'PENDING_SIGN' ||
      (annex.type !== 'CHANGE_VEHICLE' && annex.status !== 'SIGNED')
  );
  const { mutateAsync, isPending } = useUnassignVehicleToContractMutation(
    contract.contractId
  );
  const handleOnConfirm = () => {
    mutateAsync().catch(() => addErrorNotification());
    toggleOff();
  };
  return (
    <>
      <Button
        emphasis="low"
        variant="danger"
        onClick={toggleOn}
        disabled={areChecksOutPending || hasPendingAnnex}
      >
        <FormattedMessage {...messages.unassignLicensePlate} />
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        title={formatMessage(messages.unassignLicensePlate)}
        content={formatMessage(messages.areYouSureToUnassignLicensePlate, {
          licensePlate: contract.vehicle?.assignedVehicle?.licensePlate,
        })}
        onClose={toggleOff}
        onConfirm={handleOnConfirm}
        loading={isPending}
      />
    </>
  );
};
