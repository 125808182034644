import { Contract, ContractStatus } from '@cooltra/station-based-api';

export type ContractPath =
  | 'overview'
  | 'details'
  | 'driver'
  | 'second-driver'
  | 'vehicles'
  | 'extras'
  | 'payments'
  | 'checks-damages'
  | 'click-and-ride';

export const isStatusPermitted = (
  contractPath: ContractPath,
  status: ContractStatus,
  isMigrated: boolean,
  experimental: boolean
) => {
  const isNew = status === 'NEW';
  const isDraft = status === 'DRAFT';
  const isBooked = status === 'BOOKED';
  const isActive = status === 'ACTIVE';
  const isCancelled = status === 'CANCELED';
  const isNoShow = status === 'NO_SHOW';
  const isClosed = status === 'CLOSED';

  if (isMigrated) {
    switch (contractPath) {
      case 'overview':
        return (
          isDraft || isBooked || isActive || isCancelled || isNoShow || isClosed
        );
      default:
        return false;
    }
  }

  switch (contractPath) {
    case 'overview':
      return (
        isDraft || isBooked || isActive || isCancelled || isNoShow || isClosed
      );
    case 'details':
      return isNew || isDraft || isBooked || isActive;
    case 'driver':
      return isDraft || isBooked || isActive || isClosed || isNoShow;
    case 'second-driver':
      return isDraft || isBooked || isActive || isClosed || isNoShow;
    case 'vehicles':
      return isDraft || isBooked || (isActive && experimental);
    case 'extras':
      return isDraft || isBooked || isActive;
    case 'payments':
      return isDraft || isBooked || isActive || isClosed || isNoShow;
    case 'checks-damages':
      return isDraft || isBooked || isActive || isClosed;
    case 'click-and-ride':
      return isDraft || isBooked || isActive || isClosed;
  }
};

export const isLinkEnabled = (
  contractPath: ContractPath,
  { status, vehicle, isMigrated, hasClickAndRide }: Contract,
  experimental: boolean = false
) => {
  const isCorrectStatus = isStatusPermitted(
    contractPath,
    status,
    isMigrated,
    experimental
  );

  if (!isCorrectStatus) {
    return false;
  }

  switch (contractPath) {
    case 'overview':
      return true;
    case 'details':
      return true;
    case 'driver':
      return true;
    case 'second-driver':
      return true;
    case 'vehicles':
      return true;
    case 'extras':
      return !!vehicle;
    case 'payments':
      return !!vehicle;
    case 'checks-damages':
      return !!vehicle?.assignedVehicle;
    case 'click-and-ride':
      return hasClickAndRide && !!vehicle?.assignedVehicle;
  }
};
