import { VehicleStatus } from '@cooltra/station-based-api';
import { defineMessages } from 'react-intl';

export default defineMessages({
  assign: {
    defaultMessage: 'Assign',
  },
  theLicensePlateHasBeenAssigned: {
    defaultMessage: 'The license plate has been assigned',
  },
});

export const statusLabels = defineMessages<VehicleStatus>({
  AVAILABLE: {
    defaultMessage: 'Available',
  },
  IN_TRANSFER: {
    defaultMessage: 'In Transfer',
  },
  IN_TRANSIT: {
    defaultMessage: 'In Transit',
  },
  RENTED: {
    defaultMessage: 'Rented',
  },
  RESERVED: {
    defaultMessage: 'Reserved',
  },
  UNDER_REPAIR: {
    defaultMessage: 'Under repair',
  },
  TO_BE_PICKED_UP: {
    defaultMessage: 'To be picked up',
  },
});
